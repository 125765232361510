import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeOffType } from '@app/models/time-off-v3/time-off-type.model';
import { Schedule } from '@models/payroll/schedule.model';
import { PayrollResources } from '@payroll/payroll.resources';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

type XeroReportType = 'Invoice' | 'JournalEntry';

export type Settings = {
    integrationWithTimeOff?: boolean;
    timeOffPaidVacationTypeId?: number | null;
    sendPaystubNotificationEmails?: boolean;
    allowCovidWageSubsidy?: boolean;
    displayTimeOffTimeBalanceInPaystub?: boolean;
    displayTimeOffMoneyBalanceInPaystub?: boolean;
    schedule?: Schedule | null;
    vacationPayAccrualMethod?: null | string;
    displayTimeOffTimeBalanceInTimeOffView?: boolean;
    displayTimeOffMoneyBalanceInTimeOffView?: boolean;
    displayTimeOffNegativeBalanceInPaystub?: boolean;
    allowAutomaticRoeSubmissions?: boolean;
    informPreviousPayrollProviderToSubmitRoe?: boolean;
    shouldDelayPaystubToChequeDate?: boolean;
    roeContactFirstName?: null | string;
    roeContactLastName?: null | string;
    roeContactAreaCode?: null | string;
    roeContactPhoneNumber?: null | string;
    roeContactPhoneExtensionNumber?: null | string;
    showOnboardingMenu?: boolean;
    wcbActivated?: boolean;
    showHoursPage?: boolean;
    reportJournalEntryReportsToXeroAs?: XeroReportType;
    qhsfRate?: null | string;
    taxFormContactFirstName?: null | string;
    taxFormContactLastName?: null | string;
    taxFormContactAreaCode?: null | string;
    taxFormContactPhoneNumber?: null | string;
    taxFormContactPhoneExtensionNumber?: null | string;
    taxFormContactEmail?: null | string;
};

@Injectable()
export class PayrollSettingsService {
    loading$ = new BehaviorSubject(false);

    settings$: BehaviorSubject<Settings> = new BehaviorSubject<Settings>({});
    schedule: Schedule;
    timeOffTypes: TimeOffType[] = [];
    settings: Settings;
    hasLoaded = false;

    defaultSettings: Settings = {
        integrationWithTimeOff: false,
        timeOffPaidVacationTypeId: null,
        sendPaystubNotificationEmails: false,
        allowCovidWageSubsidy: false,
        displayTimeOffTimeBalanceInPaystub: false,
        displayTimeOffMoneyBalanceInPaystub: false,
        schedule: null,
        vacationPayAccrualMethod: null,
        displayTimeOffTimeBalanceInTimeOffView: false,
        displayTimeOffMoneyBalanceInTimeOffView: false,
        displayTimeOffNegativeBalanceInPaystub: false,
        allowAutomaticRoeSubmissions: false,
        informPreviousPayrollProviderToSubmitRoe: false,
        shouldDelayPaystubToChequeDate: false,
        roeContactFirstName: null,
        roeContactLastName: null,
        roeContactAreaCode: null,
        roeContactPhoneNumber: null,
        roeContactPhoneExtensionNumber: null,
        showOnboardingMenu: true,
        wcbActivated: false,
        showHoursPage: false,
        taxFormContactFirstName: null,
        taxFormContactLastName: null,
        taxFormContactAreaCode: null,
        taxFormContactPhoneNumber: null,
        taxFormContactPhoneExtensionNumber: null,
        taxFormContactEmail: null,
    };

    readonly isVacationYTDNavigable = true;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.setDefaultSettings();
    }

    async reload(): Promise<void> {
        this.hasLoaded = false;
        return this.load();
    }

    async load(): Promise<void> {
        if (this.hasLoaded) {
            return;
        }

        if (!this.needsPayrollSettings()) {
            this.setDefaultSettings();
            return;
        }

        this.loading$.next(true);

        const [settings, schedule] = await Promise.all([this.loadSettings(), this.loadSchedule()]);
        settings.schedule = schedule;
        this.settings = settings;
        this.settings$.next(settings);

        if (this.settings.qhsfRate && this.settings.qhsfRate.toString().includes('%')) {
            this.settings.qhsfRate = this.settings.qhsfRate.replace('%', '');
        }

        this.hasLoaded = true;
        this.loading$.next(false);
    }

    isPayrollEnabled(): any {
        return this.auth.company.modules.find((module) => module.name === 'Payroll');
    }

    hasPayrollTenantId(): any {
        return this.auth.company.prTenantId;
    }
    needsPayrollSettings(): boolean {
        return this.isPayrollEnabled() && this.hasPayrollTenantId() && this.auth.can('accessPayroll');
    }

    setDefaultSettings(): void {
        this.settings = this.defaultSettings;
    }

    async save(): Promise<void> {
        this.loading$.next(true);

        const settingsClone = Object.assign({}, this.settings$.value);

        if (settingsClone.qhsfRate) {
            settingsClone.qhsfRate += '%';
        }

        const payload = {
            data: {
                attributes: settingsClone,
            },
        };

        await this.http.put(this.settingsPath, payload).toPromise();

        this.loading$.next(false);
    }

    hasPaidTimeOffType(): boolean {
        return this.timeOffTypes.length > 0;
    }

    isPayrollSetupNavigable(): boolean {
        return this.settings.showOnboardingMenu;
    }

    private async loadSettings(): Promise<Settings> {
        const response = await this.http
            .get(this.settingsPath)
            .toPromise()
            .catch((error) => {
                this.setDefaultSettings();
                // We are capturing this error twice as we want to be able to log errors on Sentry
                // to determine issues as well as throw an error to return the execution of this promise
                Sentry.captureException(error);
                throw error;
            });
        return response['data']['attributes'];
    }

    private async loadSchedule(): Promise<Schedule> {
        const response = await this.http
            .get(this.schedulePath)
            .toPromise()
            .catch((error) => {
                this.setDefaultSettings();
                // We are capturing this error twice as we want to be able to log errors on Sentry
                // to determine issues as well as throw an error to return the execution of this promise
                Sentry.captureException(error);
                throw error;
            });

        return new Schedule(response['data']['attributes']);
    }

    private get schedulePath(): string {
        return PayrollResources.Schedule.replace(':company', this.auth.company.id.toString());
    }

    private get settingsPath(): string {
        return PayrollResources.Setting.replace(':company', this.auth.company.id.toString());
    }
}
