import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from '@app/models/company/company.model';
import { Employee } from '@app/models/employee/employee.model';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import {
    TimeOffDateFormatDashes,
    TimeOffDateMonthDayDateShortPipeFormat,
} from '@app/modules/time-off-v3/meta/time-off-meta';
import { AppDatePipe } from '@app/pipes';
import { isTimeOffBalanceDateToday } from '@time-off-v3/functions/settings';
import moment from 'moment';

const carryoverHappensNextYear = (carryoverDate: moment.Moment): boolean => {
    const carryoverMonth = carryoverDate.month();
    const carryoverDay = carryoverDate.date();

    return (
        carryoverMonth < moment().month() || (carryoverMonth === moment().month() && carryoverDay <= moment().date())
    );
};

export const DisplayDateSetting = {
    YEAR_END: 'Year End',
    TODAY: 'Today',
} as const;
export type ProfileBalanceDateSetting = typeof DisplayDateSetting.YEAR_END | typeof DisplayDateSetting.TODAY;

@Injectable()
export class TimeOffProfileBalanceDisplayService {
    private _balanceDateLabel: ProfileBalanceDateSetting;
    private _company: Company;

    constructor(
        @Inject(Employee) private _employee: Employee,
        @Inject(isTimeOffBalanceDateToday) private _isTimeOffBalanceDateToday = isTimeOffBalanceDateToday,
        private appDatePipe: AppDatePipe
    ) {
        this._company = this._employee.company;
    }

    /**
     * Returns the company's "Profile Balance Display Date" setting, which dictates whether or not
     * to display current Time-Off balances as of "Today" or as of "Year End".
     */
    public get balanceDateSetting(): Promise<ProfileBalanceDateSetting> {
        return new Promise((resolve, _reject) => {
            if (this._balanceDateLabel) {
                return resolve(this._balanceDateLabel);
            }

            this._isTimeOffBalanceDateToday(this._company.id).then((isToday) => {
                this._balanceDateLabel = isToday ? DisplayDateSetting.TODAY : DisplayDateSetting.YEAR_END;
                return resolve(this._balanceDateLabel);
            });
        });
    }

    /**
     * Used when the display date setting on the company is "Year End". Appends the date of
     * the carryover on the timeOffPolicy sub one day formatted as `(Jan 4)` for example.
     */
    public async balanceDateLabelFor(timeOffPolicy: TimeOffPolicy): Promise<string> {
        if (timeOffPolicy.isUnlimited) {
            return '';
        }
        if (await this.doesNotUseYearEndDateLabel()) {
            return '';
        }

        if (timeOffPolicy.carriesOverOnHireDate) {
            return `(${this.appDatePipe.transform(
                moment(this._employee.hiredAt).subtract(1, 'day'),
                TimeOffDateMonthDayDateShortPipeFormat
            )})`;
        }

        if (timeOffPolicy.carryoverDate === null) {
            return `(${this.appDatePipe.transform(moment().endOf('year'), TimeOffDateMonthDayDateShortPipeFormat)})`;
        }

        /*
         * This if statement checks if the carryover date of the time off policy is the same as the start of the next year.
         * If it is, it returns the formatted date of the end of the current year.
         * This ensures that the carryover date is correctly displayed as the end of the current year
         * when the carryover happens at the start of the next year (Jan 1st next year).
         */
        if (moment(timeOffPolicy.carryoverDate).format('MM-DD') === moment().startOf('year').format('MM-DD')) {
            return `(${this.appDatePipe.transform(moment().endOf('year'), TimeOffDateMonthDayDateShortPipeFormat)})`;
        }

        return `(${this.appDatePipe.transform(
            moment(timeOffPolicy.carryoverDate),
            TimeOffDateMonthDayDateShortPipeFormat
        )})`;
    }

    /**
     * Returns the date that should be used when calculating the timeOffPolicy's "Year End" balance.
     */
    public async balanceDateFor(timeOffPolicy: TimeOffPolicy): Promise<string> {
        if ((await this.balanceDateSetting) === DisplayDateSetting.TODAY) {
            return moment().format(TimeOffDateFormatDashes);
        }

        if (timeOffPolicy.carryoverDate === null && !timeOffPolicy.carriesOverOnHireDate) {
            return moment().endOf('year').format(TimeOffDateFormatDashes);
        }

        const carryoverDate = timeOffPolicy.carriesOverOnHireDate
            ? moment(this._employee.hiredAt)
            : moment(timeOffPolicy.carryoverDate);

        if (carryoverHappensNextYear(carryoverDate)) {
            return carryoverDate
                .set('year', moment().add(1, 'years').year())
                .subtract(1, 'days')
                .format(TimeOffDateFormatDashes);
        }

        // This setting is for when a user select specific month.
        return carryoverDate.set('year', moment().year()).format(TimeOffDateFormatDashes);
    }

    private async doesNotUseYearEndDateLabel(): Promise<boolean> {
        return (await this.balanceDateSetting) === DisplayDateSetting.TODAY;
    }
}

export const ProfileBalanceDisplayServiceProvider = {
    provide: TimeOffProfileBalanceDisplayService,
    useFactory: (route: ActivatedRoute): TimeOffProfileBalanceDisplayService => {
        return new TimeOffProfileBalanceDisplayService(
            route.snapshot.data.models.get('employee'),
            isTimeOffBalanceDateToday,
            new AppDatePipe()
        );
    },
    deps: [ActivatedRoute],
};
